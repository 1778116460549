import styled from 'styled-components';

export default styled.div`
    margin-bottom: 2rem;
`;

export const WrapperSchema = styled.div`
    padding: 1rem 0;
    font-size: 0.8125rem;
`;

export const WrapperHeaders = styled(WrapperSchema)``;

export const WrapperDescription = styled.div`
    margin-bottom: 1rem;
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;
