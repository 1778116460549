import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import { getExampleValue, loadSchema, getRefComponent, listAllPropertiesOfSchema } from '../utils/parser';
import PrettyPrint from './prettyprint';
import { WrapperSchema, WrapperHeaders, WrapperDescription } from '../enb-styled/Wrapper';
import { countTotalProperties } from '../utils/helper';
import { initialItemsToShow } from '../utils/constants';

const spec = require(process.env.GATSBY_API_SPEC_URL);

const WrapperResponse = styled.div`
    padding: 1rem 0;
`;

export default ({ response }) => {
    const { description, headers } = response;

    const [sample, setSample] = useState(null);

    const [type, setType] = useState(null);
    const [required, setRequired] = useState([]);
    const [allProperties, setAllProperties] = useState([]);

    const [totalProperties, setTotalProperties] = useState(0);
    const [isExpand, setIsExpanded] = useState(true);

    const init = useCallback(async () => {
        let schema = await loadSchema(response, spec);
        if (schema) {
            if (schema.items) {
                schema = schema.items;
            }
            const exampleValue = getExampleValue(schema, spec);
            setSample(exampleValue);

            if (schema.$ref) {
                const comp = await getRefComponent(schema.$ref, spec);
                setType(comp.type);
                setRequired(comp.required);

                const allProperties = await listAllPropertiesOfSchema(comp, spec);
                setAllProperties(allProperties);

                const totalProps = countTotalProperties(allProperties);
                setTotalProperties(totalProps);

                if (totalProps > initialItemsToShow) {
                    setIsExpanded(false);
                }
            }
        }
    }, [response]);

    useEffect(() => init(), []);

    function toggleSchema() {
        setIsExpanded(!isExpand);
    }

    let countProperty = 0;
    /**
     * Display the list of properties, with name and type
     * @param {array} properties
     * @returns {jsx}
     */
    function displayProperties(properties) {
        return (
            <ul>
                {properties.map((item) => {
                    countProperty += 1;
                    if (countProperty > initialItemsToShow && isExpand === false) {
                        return null;
                    }

                    const isRequired = required && required.indexOf(item.name) > -1;

                    return (
                        <li key={item.name}>
                            <div>
                                {item.name}&nbsp;
                                <span className={`cell--${item.type}`}>{`(${item.type})`}</span>
                                {isRequired && <span style={{ color: '#e6555f' }}>&nbsp;*</span>}
                                {item.properties && displayProperties(item.properties)}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }

    /**
     * Display the headers object returned from api call, and extract the Location information.
     * @param {*} headersObj
     * @returns {jxs}
     */
    function displayHeaders(headersObj) {
        const { Location } = headersObj;
        if (!Location) {
            return null;
        }
        const { description, schema } = Location;
        const type = schema?.type;
        return (
            <WrapperHeaders>
                <div style={{ marginBottom: '0.5rem' }}>
                    <strong>Headers</strong>
                </div>
                <span style={{ marginRight: '0.5rem' }}>Location:</span>
                <span style={{ marginRight: '0.5rem' }}>{description}</span>
                <span className={`cell--${type}`}>({type})</span>
            </WrapperHeaders>
        );
    }

    return (
        <WrapperResponse>
            {description && <WrapperDescription>{description}</WrapperDescription>}

            {headers && <div>{displayHeaders(headers)}</div>}

            {(totalProperties || sample) && (
                <Tabs>
                    <TabList>
                        <Tab>Schema</Tab>
                        <Tab>Example</Tab>
                    </TabList>
                    <TabPanel>
                        <WrapperSchema>
                            {type && (
                                <div>
                                    <p>
                                        <strong>Type:</strong> <span className={`cell--${type}`}>{type}</span>
                                    </p>
                                </div>
                            )}
                            {displayProperties(allProperties)}
                            {totalProperties > initialItemsToShow && (
                                <div className={isExpand ? `toggleExtra is-on` : `toggleExtra`} onClick={toggleSchema}>
                                    {isExpand ? 'collapse' : '...show more'}
                                </div>
                            )}
                        </WrapperSchema>
                    </TabPanel>
                    <TabPanel>{sample && <PrettyPrint jsonObj={sample}></PrettyPrint>}</TabPanel>
                </Tabs>
            )}
        </WrapperResponse>
    );
};
