import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PrettyPrint from './prettyprint';
import { getRefComponent, getExampleValue, loadSchema, listAllPropertiesOfSchema } from '../utils/parser';
import { WrapperSchema, WrapperDescription } from '../enb-styled/Wrapper';
import { countTotalProperties } from '../utils/helper';
import { initialItemsToShow } from '../utils/constants';

const spec = require(process.env.GATSBY_API_SPEC_URL);

export default ({ requestBody }) => {
    const { description } = requestBody;

    const [sample, setSample] = useState(null);

    const [type, setType] = useState(null);
    const [required, setRequired] = useState([]);
    const [allProperties, setAllProperties] = useState([]);

    const [totalProperties, setTotalProperties] = useState(0);
    const [isExpand, setIsExpanded] = useState(true);

    useEffect(async () => {
        const schema = await loadSchema(requestBody, spec);
        if (schema) {
            const exampleValue = getExampleValue(schema, spec);
            setSample(exampleValue);
        }

        const comp = await getRefComponent(schema.$ref, spec);
        setType(comp.type);
        setRequired(comp.required);

        const allProperties = await listAllPropertiesOfSchema(comp, spec);
        setAllProperties(allProperties);

        const totalProps = countTotalProperties(allProperties);
        setTotalProperties(totalProps);

        if (totalProps > initialItemsToShow) {
            setIsExpanded(false);
        }
    }, []);

    function toggleSchema() {
        setIsExpanded(!isExpand);
    }

    let countProperty = 0;
    /**
     * Display the list of properties, with name and type
     * @param {array} properties
     * @returns {jsx}
     */
    function displayProperties(properties) {
        return (
            <ul>
                {properties.map((item) => {
                    countProperty += 1;
                    if (countProperty > initialItemsToShow && isExpand === false) {
                        return null;
                    }

                    const isRequired = required && required.indexOf(item.name) > -1;

                    return (
                        <li key={item.name}>
                            <div>
                                {item.name}&nbsp;
                                <span className={`cell--${item.type}`}>{`(${item.type})`}</span>
                                {isRequired && <span style={{ color: '#e6555f' }}>&nbsp;*</span>}
                                {item.properties && displayProperties(item.properties)}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <div className="section">
            <h4 className="section-title">Request Body</h4>

            {description && <WrapperDescription>{description}</WrapperDescription>}

            <Tabs>
                <TabList>
                    <Tab>Schema</Tab>
                    <Tab>Example</Tab>
                </TabList>
                <TabPanel>
                    <WrapperSchema>
                        {type && (
                            <div>
                                <p>
                                    <strong>Type:</strong> <span className={`cell--${type}`}>{type}</span>
                                </p>
                            </div>
                        )}
                        {displayProperties(allProperties)}
                        {totalProperties > initialItemsToShow && (
                            <div className={isExpand ? `toggleExtra is-on` : `toggleExtra`} onClick={toggleSchema}>
                                {isExpand ? 'collapse' : '...show more'}
                            </div>
                        )}
                    </WrapperSchema>
                </TabPanel>
                <TabPanel>{sample && <PrettyPrint jsonObj={sample}></PrettyPrint>}</TabPanel>
            </Tabs>
        </div>
    );
};
