import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Response from './response';

export default ({ responses }) => {
    const statusCodes = Object.keys(responses);

    if (!statusCodes?.length) {
        return null;
    }

    return (
        <div className="section">
            <h2 className="section-title">Responses</h2>
            <Tabs>
                <TabList>
                    {statusCodes.map((status) => (
                        <Tab key={status}>{status}</Tab>
                    ))}
                </TabList>

                {statusCodes.map((status) => {
                    return (
                        <TabPanel key={status}>
                            <Response response={responses[status]} />
                        </TabPanel>
                    );
                })}
            </Tabs>
        </div>
    );
};
