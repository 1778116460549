import data from '../content/enablehr.json';

export const objectToArray = (obj) => {
    const keys = Object.keys(obj);
    return keys.map((key) => obj[key]);
};

/**
 * Get the object by component name
 * @param {String} componentName
 */
export const getComponentObject = (componentName) => {
    const { components } = data;
    return components.schemas[componentName];
};

export const PATH_PREFIX_DOCS = 'docs';

/**
 * Count the total of all properties, including sub-properties
 * @param {array} items
 * @returns {boolean}
 */
export const countTotalProperties = (items) => {
    return items.reduce(function (accumulator, currentItem) {
        accumulator += 1;
        if (currentItem.properties) {
            return countTotalProperties(currentItem.properties) + accumulator;
        }
        return accumulator;
    }, 0);
};
