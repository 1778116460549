import SwaggerParser from 'swagger-parser';
const OpenAPISampler = require('openapi-sampler');

const parser = new SwaggerParser();

async function loadSpec(spec_url) {
    const api = await parser.parse(spec_url);
    return api;
}

/**
 *
 * @param {string} $ref - the referenced component
 * @param {string} spec_url - the string url of the api file
 * @returns
 */
async function getRefComponent($ref = '', spec_url) {
    const $refs = await parser.resolve(spec_url);
    const component = $refs.get($ref);
    return component;
}

async function loadPaths(spec_url) {
    const api = await loadSpec(spec_url);
    const paths = Object.keys(api.paths).map((path) => path);
    return paths;
}

/**
 * Get sample example value
 * @param {object} schema
 * @param {string} api - the string url of the api file
 * @returns {object}
 */
function getExampleValue(schema, api) {
    return OpenAPISampler.sample(schema, null, api);
}

/**
 * Retrieve the schema object by a single response object of a status code, such as 200, or a requestBody object
 * @param {object} obj - this can the response or requestBody object
 * @param {string} api - the string url of the api file
 * @returns {object}
 */
async function loadSchema(obj, api) {
    if (!obj && !obj.content && !obj.content['application/json'] && !obj.$ref && !obj.properties && !obj.allOf) {
        return;
    }

    if (obj.$ref) {
        const component = await getRefComponent(obj.$ref, api);
        return await loadSchema(component, api);
    }

    if (obj.properties && obj.type) {
        return obj;
    }

    if (obj.allOf) {
        return obj;
    }

    try {
        return obj?.content['application/json']?.schema;
    } catch (err) {
        return;
    }
}

async function listAllPropertiesOfSchema(schema, api) {
    async function handleObject(name, $ref) {
        let properties;
        const schema = await getRefComponent($ref, api);
        if (schema.properties) {
            properties = await listAllPropertiesOfSchema(schema, api);
        } else if (schema.type === 'array') {
            if (schema?.items?.$ref) {
                const itemSchema = await getRefComponent(schema.items.$ref, api);
                properties = await listAllPropertiesOfSchema(itemSchema, api);
            } else if (schema.properties) {
                properties = await listAllPropertiesOfSchema(schema, api);
            }
        } else if (schema.allOf) {
            properties = await handleAllOf(schema.allOf, api);
        }
        return {
            name,
            type: schema.type,
            properties,
        };
    }

    async function handleArray(name, type, $ref) {
        let properties;
        const schema = await getRefComponent($ref, api);
        if (schema?.items?.$ref) {
            const itemSchema = await getRefComponent(schema.items.$ref, api);
            properties = await listAllPropertiesOfSchema(itemSchema, api);
        } else if (schema.properties) {
            properties = await listAllPropertiesOfSchema(schema, api);
        }
        return {
            name,
            type,
            properties,
        };
    }

    async function handleAllOf(allOf, api) {
        const properties = [];
        for (const { $ref } of allOf) {
            const schema = await getRefComponent($ref, api);
            const itemProperties = await listAllPropertiesOfSchema(schema, api);
            const itemKeys = Object.keys(itemProperties);
            for (const key of itemKeys) {
                properties.push(itemProperties[key]);
            }
        }
        return properties;
    }

    const result = [];
    if (schema.allOf) {
        return [
            {
                name: schema.description,
                type: schema.type,
                properties: await handleAllOf(schema.allOf, api),
            },
        ];
    } else {
        const keys = Object.keys(schema.properties);
        for (const name of keys) {
            const { $ref, type, description, items: { $ref: $itemsref } = {} } = schema.properties[name];
            if ($ref) {
                const o = await handleObject(name, $ref);
                result.push(o);
            } else if (type === 'array') {
                const o = await handleArray(name, description, $itemsref);
                result.push(o);
            } else {
                result.push({
                    name,
                    type,
                });
            }
        }
        return result;
    }
}

export { loadSpec, getRefComponent, loadPaths, getExampleValue, loadSchema, listAllPropertiesOfSchema };
