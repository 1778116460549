import React from 'react';
import styled from 'styled-components';
import Parameter from './parameter';

export default ({ parameters }) => {
    return (
        <div className="section">
            {parameters.length > 0 && (
                <div>
                    <h4 className="section-title">Query String Parameters</h4>
                    <WrappedLink>
                        <a href="/development/pagination/">Further Details</a>
                    </WrappedLink>
                    <div className="table-responsive">
                        <table className="table table-parameters">
                            <thead>
                                <tr>
                                    <th style={{ width: '10%' }}>Name</th>
                                    <th style={{ width: '6%' }}>Type</th>
                                    <th style={{ width: '6%' }}>Required</th>
                                    <th className="text-center" style={{ width: '24%' }}>
                                        Example
                                    </th>
                                    <th style={{ width: '54%' }} className="d-none d-sm-block">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {parameters.map((p) => (
                                    <Parameter key={p.name} parameter={p} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

const WrappedLink = styled.div`
    font-size: 0.875rem;
    margin: 1rem 0;
`;
