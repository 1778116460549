import React from 'react';

const Scope = ({ content }) => {
    if (!content) {
        return null;
    }

    const scope = extractScope(content);

    return (
        <div className="mt-3">
            <span className="h4">Scope</span>: <code>{scope}</code>
        </div>
    );
};

export default Scope;

export function extractScope(security) {
    if (!security || !security[0]) {
        return;
    }
    const { OAuth2 } = security[0];
    return OAuth2.join(', ');
}
